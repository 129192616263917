import React from "react"
import { css } from "@emotion/core"
import { Image } from "../common"

import Bracket from "../../images/projects/logo_bracket.svg"

const Logo = ({ src, className }) => (
  <div
    css={css`
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    `}
    className={className}
  >
    <Image
      src={Bracket}
      css={css`
        margin-right: 30px;
        height: 100%;

        @media (max-width: 650px) {
          margin-right: 10px;
        }
      `}
    />
    <Image
      src={src}
      css={css`
        height: 50%;
      `}
    />
    <Image
      src={Bracket}
      css={css`
        margin-left: 30px;
        height: 100%;
        transform: rotateZ(180deg);

        @media (max-width: 650px) {
          margin-left: 10px;
        }
      `}
    />
  </div>
)

export default Logo
