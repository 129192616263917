import React from "react"
import { css } from "@emotion/core"

const ProjectHero = ({ title, subtitle }) => {
  return (
    <div
      css={theme => css`
        position: relative;
        padding: ${theme.constants.pageMargin};
        padding-left: 165px;
        height: ${theme.constants.heroHeight - theme.constants.headerHeight}px;
        top: ${theme.constants.headerHeight}px;
        text-align: center;

        @media (max-width: 1200px) {
          padding: ${theme.constants.mediumpageMargin};
        }
        @media (max-width: 1000px) {
          height: calc(
            ${theme.constants.mediumheroHeight}px -
              ${theme.constants.headerHeight}px
          );
          padding: ${theme.constants.smallpageMargin};

          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      `}
    >
      <div
        css={theme => css`
          font-size: ${theme.constants.heroTitleSize}px;
          font-weight: ${theme.constants.bold};
          color: ${theme.colors.white};
          width: 75%;
          padding-top: 220px;
          margin: 0 auto;
          padding-bottom: 40px;

          @media (max-width: 1000px) {
            width: 100%;
            text-align: center;
            font-size: ${theme.constants.semiFontSize}px;
            padding-top: 50px;
          }

          @media (max-width: 880px) {
            font-size: ${theme.constants.mediumFontSize}px;
            padding-top: 0px;
          }
        `}
      >
        {title}
      </div>
      <div
        css={theme => css`
          font-size: ${theme.constants.heroDescriptionSize}px;
          font-weight: ${theme.constants.extraLight};
          color: ${theme.colors.white};
          width: 70%;
          padding-bottom: 40px;
          margin: 0 auto;

          @media (max-width: 1000px) {
            font-size: ${theme.constants.smallFontSize}px;
            width: 100%;
            text-align: center;
          }

          @media (max-width: 880px) {
            font-size: ${theme.constants.extraSmallFontSize}px;
            margin-bottom: 80px;
          }

          @media (max-width: 500px) {
            margin-bottom: 10px;
          }
        `}
      >
        {subtitle}
      </div>
    </div>
  )
}
export default ProjectHero
