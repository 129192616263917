import React from "react"
import { css } from "@emotion/core"

import { useWindowWidth } from "../../hooks/useWindowWidth"
import { GradientLinkButton } from "../common"

const NextProject = ({ image, title, description, to }) => {
  const width = useWindowWidth()

  return (
    <div
      css={css`
        background: url(${image});
        background-size: cover;
        background-position-x: center;
        background-position-y: top;
        background-repeat: no-repeat;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        @media (max-width: 1000px) {
          padding: 50px;
        }

        @media (max-width: 880px) {
          padding: 50px 20px;
        }

        @media (max-width: 600px) {
          padding-top: 160px;
          padding-bottom: 160px;
        }
      `}
    >
      <div
        css={theme => css`
          font-size: ${theme.constants.contactBoxTitleSize}px;
          font-weight: ${theme.constants.demi};
          color: ${theme.colors.white};
          margin-bottom: 30px;
          text-align: center;
        `}
      >
        {title}
      </div>
      <div
        css={theme => css`
          font-size: ${theme.constants.contactBoxDescriptionSize}px;
          font-weight: ${theme.constants.light};
          color: ${theme.colors.white};
          text-align: center;

          margin: 0 128px;
          margin-bottom: 16px;

          @media (max-width: 880px) {
            margin: 0;
            margin-bottom: 16px;
          }
        `}
      >
        {description}
      </div>
      <GradientLinkButton
        centered={width <= 880}
        css={css`
          width: 300px;

          @media (max-width: 880px) {
            width: 100%;
          }
        `}
        to={`/projects/${to}`}
      >
        see next project
      </GradientLinkButton>
    </div>
  )
}

export default NextProject
