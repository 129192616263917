import React from "react"
import { css } from "@emotion/core"
import { Image } from "../common"
import PhoneFrameImage from "../../images/projects/doki/phoneframe.png"

const DoubleColumn = ({
  image,
  title,
  description,
  flip,
  top = 100,
  first = false,
  border = 100,
  className,
  column1 = 4,
  column2 = 6,
  video,
  footer: Footer,
  small = false,
  imageOverride,
}) => {
  return (
    <div
      css={css`
        display: flex;

        @media (max-width: 650px) {
          flex-direction: column;
        }
      `}
      className={className}
    >
      <div
        css={css`
          flex: ${column1};
          order: ${flip ? 0 : 1};
          display: flex;
          flex-direction: column;
          padding: ${top}px 0;

          @media (max-width: 650px) {
            padding: 20px 0;
            order: 0;
          }

          @media (min-width: 651px) {
            flex: ${Math.max(column1, column2)};
          }

          @media (min-width: 1920px) {
            justify-content: center;
          }
        `}
      >
        <div
          css={theme => css`
            font-size: ${first
              ? theme.constants.semiFontSize
              : theme.constants.mediumFontSize}px;
            font-weight: ${theme.constants.demi};
            margin-bottom: 40px;
            text-align: left;

            @media (max-width: 1000px) {
              font-size: ${theme.constants.mediumFontSize}px;
              margin-bottom: 20px;
              text-align: left;
            }

            @media (max-width: 650px) {
              text-align: center;
            }
          `}
        >
          {title}
        </div>
        <div
          css={theme => css`
            font-size: ${first
              ? theme.constants.mediumFontSize
              : theme.constants.smallFontSize}px;
            font-weight: ${theme.constants.light};
            text-align: left;
            margin-bottom: 40px;

            @media (max-width: 1000px) {
              font-size: ${theme.constants.smallFontSize}px;
              margin-bottom: 15px;
              text-align: left;
            }

            @media (max-width: 650px) {
              text-align: center;
            }
          `}
        >
          {description}
        </div>
        {Footer && <Footer />}
      </div>
      {video ? (
        <div
          css={css`
            position: relative;
            flex: ${column2};
          `}
        >
          <Image
            src={PhoneFrameImage}
            css={css`
              position: absolute;
              height: 100%;
              right: 0;

              @media (max-width: 650px) {
                position: relative;
                width: 100%;
              }
            `}
          />
          <video
            playsInline
            autoPlay
            loop
            src={video}
            muted
            css={css`
              position: absolute;
              right: 0;
              height: 100%;
              transform: scale(0.76) translateX(9%);
            `}
          />
        </div>
      ) : (
        <div
          css={[
            css`
              background: url(${image});
              background-size: contain;
              background-position: ${flip ? "100%" : "0%"} 0%;
              background-repeat: no-repeat;
              background-origin: content-box;
              padding-left: ${flip ? border : 0}px;
              padding-right: ${!flip ? border : 0}px;

              @media (max-width: 650px) {
                padding: 0;
                background-position: 50% ${small ? 50 : 0}%;
                height: ${small ? "300px" : "100vw"};
              }

              @media (min-width: 651px) {
                flex: ${column2};
              }

              @media (min-width: 1001px) {
                flex: ${Math.min(column1, column2)};
              }
            `,
            imageOverride,
          ]}
        />
      )}
    </div>
  )
}
export default DoubleColumn
