import React from "react"
import { css } from "@emotion/core"

const Banner = ({ image, className }) => (
  <div
    css={css`
      background: url(${image});
      height: 450px;
      background-position: 50% 50%;
      background-size: cover;
      background-repeat: no-repeat;
      margin-bottom: 10px;

      @media (max-width: 650px) {
        height: 250px;
      }
    `}
    className={className}
  />
)

export default Banner
