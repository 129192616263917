import React from "react"
import { css } from "@emotion/core"
import Layout from "../../components/Layout"
import ProjectHero from "../../components/heros/ProjectHero"
import DoubleColumn from "../../components/projects/DoubleColumn"
import Banner from "../../components/projects/Banner"
import Download from "../../components/projects/Download"
import Logo from "../../components/projects/Logo"
import NextProject from "../../components/projects/NextProject"
import { Image } from "../../components/common"

import HeroImage from "../../images/projects/aycm/hero.jpg"
import ProjectMobileImage from "../../images/projects/aycm/project_mobile.png"
import BannerImage from "../../images/projects/aycm/banner.jpg"
import ScreenshotImage from "../../images/projects/aycm/screenshot.png"
import ColumnImage from "../../images/projects/aycm/column.jpg"
import InstallImage from "../../images/projects/aycm/install.png"
import LogoImage from "../../images/projects/aycm/logo.svg"

import NextImage from "../../images/projects/info/workn.jpg"

const Next = () => (
  <NextProject
    image={NextImage}
    title="Workn"
    description="The labour market has never been so organized and optimized. Welcome to
the third industrial revolution."
    to="workn"
  />
)

const Hero = () => (
  <ProjectHero
    title="AYCM – All You Can Move"
    subtitle="The AYCM SportPass is a digital sports pass for almost all sports
facilities in Hungary."
  />
)

const AYCMProject = () => (
  <Layout withHero heroContent={Hero} heroImage={HeroImage} infoContent={Next}>
    <div
      css={theme => css`
        text-align: center;
        min-height: calc(
          100vh - ${theme.constants.infoFullHeight / 2}px -
            ${theme.constants.footerHeight}px -
            ${theme.constants.headerHeight}px
        );
        justify-content: center;
        display: flex;
        flex-direction: column;

        @media (max-width: 1000px) {
          position: relative;
          top: -100px;
        }

        @media (max-width: 650px) {
          position: relative;
          top: -130px;
        }
      `}
    >
      <div
        css={theme => css`
          margin: ${theme.constants.pageMargin};

          @media (max-width: 1200px) {
            margin: ${theme.constants.mediumpageMargin};
          }

          @media (max-width: 1000px) {
            margin: ${theme.constants.smallpageMargin};
          }
        `}
      >
        <DoubleColumn
          first
          title="Digital sports pass"
          description="The Deverest team participated in the digitalisation of the sport pass."
          image={ProjectMobileImage}
          flip
          imageOverride={css`
            @media (min-width: 1920px) {
              height: 700px;
              padding: 0;
            }
          `}
        />
      </div>
      <Banner
        image={BannerImage}
        css={css`
          @media (min-width: 1440px) {
            background-position: 50% 0%;
          }
        `}
      />
      <div
        css={theme => css`
          margin: ${theme.constants.pageMargin};

          @media (max-width: 1200px) {
            margin: ${theme.constants.mediumpageMargin};
          }

          @media (max-width: 1000px) {
            margin: ${theme.constants.smallpageMargin};
          }
          margin-top: 100px;
        `}
      >
        <div
          css={theme => css`
            font-size: ${theme.constants.smallFontSize}px;
            font-weight: ${theme.constants.extraLight};
            margin-bottom: 100px;
          `}
        >
          <p
            css={theme => css`
              font-size: ${theme.constants.mediumFontSize}px;
              font-weight: ${theme.constants.demi};
              margin-bottom: 30px;
            `}
          >
            Facility search application
          </p>
          <p>
            Through our development, we are encouraging more people to get
            active and participate in sports! In order to promote a
            health-conscious lifestyle, we&apos;ve made facility search
            application for AYCM SportPass.
          </p>
        </div>
        <DoubleColumn
          title="More than 850 access points"
          description="AYCM is a multifunctional sports pass, which is the first of its kind. It allows for more than 50,000 people, with over 850 admissions to visit almost each and every sports facility in Hungary."
          image={ScreenshotImage}
          border={0}
          top={100}
          imageOverride={css`
            @media (min-width: 651px) {
              background-position: 100% 50%;
            }

            @media (min-width: 1920px) {
              height: 700px;
              padding: 0;
            }
          `}
        />
        <DoubleColumn
          title="Quick and easy entry"
          description="Thanks to the application, the AYCM app allows club members to access the AYCM gateway points/access points through the application via a virtual ePASS, which speeds up the entry process."
          image={ColumnImage}
          top={20}
          flip
          css={css`
            margin: 50px 0;
          `}
          imageOverride={css`
            @media (max-width: 650px) {
              height: 60vw;
            }

            @media (min-width: 1920px) {
              height: 500px;
              padding: 0;
            }
          `}
        />
      </div>
      <div
        css={theme => css`
          background: linear-gradient(#333b48, #333b48);
          background-repeat: no-repeat;
          background-size: 100% 1000px;
          color: ${theme.colors.white};
          padding-top: 100px;
          text-align: center;

          @media (max-width: 650px) {
            background-size: 100% 700px;
          }

          @media (min-width: 651px) and (max-width: 930px) {
            background-size: 100% 800px;
          }
        `}
      >
        <div
          css={theme => css`
            font-size: ${theme.constants.largerFontSize}px;
            font-weight: ${theme.constants.demi};

            @media (max-width: 1200px) {
              font-size: ${theme.constants.mediumFontSize}px;
              margin: ${theme.constants.mediumpageMargin};
            }

            @media (max-width: 1000px) {
              font-size: ${theme.constants.mediumFontSize}px;
              margin: ${theme.constants.smallpageMargin};
            }
          `}
        >
          User-friendly interface
        </div>
        <div
          css={theme => css`
            font-size: ${theme.constants.mediumFontSize}px;
            width: 60%;
            margin: 0 auto;
            margin-top: 20px;

            @media (max-width: 1000px) {
              font-size: ${theme.constants.smallFontSize}px;
              width: 90%;
            }
          `}
        >
          You can download the ePASS – AYCM virtual card from the App Store or
          Google Play Store.
        </div>
        <div
          css={css`
            margin: 50px auto;
          `}
        >
          <Download
            androidLink="https://play.google.com/store/apps/details?id=com.allyoucanmove.app2"
            iosLink="https://apps.apple.com/hu/app/aycm-all-you-can-move/id1062283025"
          />
        </div>
        <Image
          src={InstallImage}
          css={theme => css`
            width: 100%;
            padding: ${theme.constants.pageMargin};

            @media (max-width: 1200px) {
              padding: ${theme.constants.mediumpageMargin};
            }

            @media (max-width: 1000px) {
              padding: ${theme.constants.smallpageMargin};
            }

            @media (min-width: 1200px) {
              max-width: 1200px;
              padding: 0;
            }

            @media (max-width: 650px) {
              display: none;
            }
          `}
        />
        <div
          css={css`
            background: url(${InstallImage});
            background-repeat: no-repeat;
            background-size: cover;
            height: 130vw;
            background-position-x: 50%;
            width: 100%;

            @media (min-width: 651px) {
              display: none;
            }
          `}
        />
      </div>
      <Logo
        src={LogoImage}
        css={css`
          height: 140px;
          margin: 0 auto;
          margin-bottom: 150px;

          @media (max-width: 650px) {
            height: 25vw;
            margin-bottom: 0;
            margin-top: 50px;
          }
        `}
      />
    </div>
  </Layout>
)

export default AYCMProject
