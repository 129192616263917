import React from "react"
import { css } from "@emotion/core"
import { ExternalLink, Image } from "../common"

import PlayStore from "../../images/projects/playstore.svg"
import AppStore from "../../images/projects/appstore.svg"

const Download = ({ androidLink, iosLink, className }) => (
  <div
    css={css`
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      @media (max-width: 650px) {
        height: 45px;
      }
    `}
    className={className}
  >
    <ExternalLink
      to={androidLink}
      css={css`
        margin-right: 50px;
        height: 100%;

        @media (max-width: 650px) {
          margin-right: 20px;
        }
      `}
    >
      <Image
        src={PlayStore}
        css={css`
          height: 100%;
        `}
      />
    </ExternalLink>
    <ExternalLink
      to={iosLink}
      css={css`
        height: 100%;
      `}
    >
      <Image
        src={AppStore}
        css={css`
          height: 100%;
        `}
      />
    </ExternalLink>
  </div>
)

export default Download
